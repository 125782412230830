import * as React from "react"
import { Link } from 'gatsby'
import Layout from '../components/layout'

const ContactPage = () => {
  return (<Layout pageHeading='Contact' pageTitle='Contact'>
    Reach out to us at <Link to="mailto:contact@prodatastream.com">contact@prodatastream.com</Link>
  </Layout>)

}

export default ContactPage
